@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
html,
body,
#root {
  /* height: 100%; */
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
section {
  overflow-x: hidden;
  overflow-y: hidden;
}
::selection {
  background-color: hsl(var(--p));
  color: white;
}
body {
  background-color: black;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
svg {
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .container {
    max-width: 1200px !important;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1300px !important;
  }
}
.grad-bg {
  background: #000000;
  background: -webkit-radial-gradient(left, #000323, #000000);
  background: -moz-radial-gradient(left, #000323, #000000);
  background: linear-gradient(to right, #000323, #000000);
}
.animate-bg {
  background-color: #000000;
  background: linear-gradient(-45deg, #000000, #000433, #000000);
  background-size: 400% 400%;
  animation: gradient 7s ease infinite;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
#menu-id.active {
  left: 0;
  overflow: auto;
}